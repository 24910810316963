import { UserIdentificationType, UserLogonResult, UserLogonResultState } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import Router, { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayoutContext } from '../../../context/layout.context'
import useSportRadarTagManager from '../../../hooks/useSportRadarTagManager'
import { resendVerificationEmail } from '../../../lib/account'
import {
  redirectAfterSuccessfulLogin,
  signInUser,
  useUser,
  useUserAccounts,
  useUserBetSettings,
} from '../../../lib/user'
import { formatDateWithTime } from '../../../util/date'
import { EnumTranslator } from '../../../util/enum-translator'
import { gtmPushLoginEvent } from '../../../util/google-tag-manager'
import { isValidEmail } from '../../../util/string'
import { FormBuilder, FormFieldConfig, FormFieldType } from '../../forms/FormBuilder'
import { useExatoFaceRecognitionResultHandler } from '../../../hooks/useExatoFaceRecognition'
import { GeoComplyReason, GeoComplyReasonCode, useGeoComply } from '../../../hooks/useGeoComplyGeoLocation'

export const LoginForm = ({ onTfaRequired, exatoFaceRecognitionFormProps, fingerprint }) => {
  const layoutContext = useLayoutContext()

  const { t, lang } = useTranslation()
  const { mutateUser } = useUser()
  const { mutateUserAccounts } = useUserAccounts()
  const { mutateUserBetSettings } = useUserBetSettings()
  const { srtmPushLogin } = useSportRadarTagManager()
  const { geolocate } = useGeoComply()

  const {
    push,
    locale,
    query: { redirectTo },
  } = useRouter()

  const [formFields, setFormFields] = useState(buildFormFields())
  const formDataRef = useRef<any>(null)

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<any>({
    mode: 'onChange',
    shouldFocusError: true,
  })

  const [logonResult, setLogonResult] = useState<UserLogonResult>(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  useEffect(() => {
    initializeForm()
  }, [lang])

  useExatoFaceRecognitionResultHandler(
    setErrorMessage,
    async () => await onSubmit(formDataRef.current, exatoFaceRecognitionFormProps.identityValidationId),
    exatoFaceRecognitionFormProps.identityValidationId,
    exatoFaceRecognitionFormProps.statusMessage,
  )

  const initializeForm = () => {
    setFormFields(buildFormFields())
  }

  function buildFormFields() {
    const username: FormFieldConfig = {
      name: t('account:Account.usernameOrEmail'),
      property: 'username',
      formFieldType: FormFieldType.Input,
      required: true,
      autocomplete: 'username',
    }
    const password: FormFieldConfig = {
      name: t('account:Account.password'),
      property: 'password',
      type: 'password',
      formFieldType: FormFieldType.Input,
      required: true,
      autocomplete: 'current-password',
      additionalActionText: t('login:LoginPage.forgotPassword'),
      additionalActionFn: () => push('/account/reset-password'),
    }
    return [username, password]
  }

  const onSubmit = async (formData, faceRecognitionIdentityValidationId?: string) => {
    formDataRef.current = formData

    setSuccessMessage(null)
    setErrorMessage(null)

    const userIdentifier = formData.username
    const userIdentificationType = isValidEmail(userIdentifier)
      ? UserIdentificationType.EmailAddress
      : UserIdentificationType.Username

    layoutContext.setLoadingState(true)

    const result = await signInUser(
      userIdentificationType,
      userIdentifier,
      formData.password,
      fingerprint,
      faceRecognitionIdentityValidationId,
    )

    if (result.accessDenied) {
      return Router.push('/access-denied')
    }

    setLogonResult(result)

    switch (result.state) {
      case UserLogonResultState.Ok:
        await onLoginSuccessful(result)
        break
      case UserLogonResultState.MailTFARequired:
      case UserLogonResultState.SMSTFARequired:
        onTfaRequired(result.twoFactorToken)
        break
      case UserLogonResultState.RequiresFaceRecognition:
        exatoFaceRecognitionFormProps.onFaceRecognitionRequired(result.faceRecognitionUrl)
        break
      default:
        onLoginFailed(result)
    }

    layoutContext.setLoadingState(false)
  }

  const onLoginSuccessful = async (result: UserLogonResult) => {
    srtmPushLogin(result?.user?.id)
    gtmPushLoginEvent(result?.user?.id)

    await Promise.all([mutateUser(), mutateUserAccounts(), mutateUserBetSettings()])
    await geolocate(GeoComplyReasonCode.LOGIN, GeoComplyReason.LOGIN, result?.user?.id)
    await redirectAfterSuccessfulLogin(result.user, locale, redirectTo as string)
  }

  const onLoginFailed = (result: UserLogonResult) => {
    const params = {
      attemptsLeft: result?.allowedAttempts - result?.failedAttempts,
      lockedUntil: formatDateWithTime(result?.lockedUntil),
    }
    setErrorMessage(EnumTranslator(t, UserLogonResultState, result.state, params))
  }

  const onVerificationEmailSent = (email: string) => {
    setSuccessMessage(t('login:LoginPage.verificationEmailSent', { email }))
    setErrorMessage(null)
    setLogonResult(null)
  }

  return (
    <FormBuilder
      _register={register}
      _errors={errors}
      _handleSubmit={handleSubmit}
      _isValid={isValid}
      _fields={formFields}
      _onSubmit={onSubmit}
      _submitText={t('login:LoginPage.submit')}
      _errorMessageAction={
        logonResult?.state === UserLogonResultState.EmailIsNotVerified ? (
          <ResendVerificationEmail userId={logonResult.user?.id} onVerificationEmailSent={onVerificationEmailSent} />
        ) : null
      }
      _errorMessage={errorMessage}
      _successMessage={successMessage}
    />
  )
}

const ResendVerificationEmail = ({ userId, onVerificationEmailSent }) => {
  const { t } = useTranslation()
  const layoutContext = useLayoutContext()

  const onResendVerificationEmail = async () => {
    layoutContext.setLoadingState(true)
    const email = await resendVerificationEmail(userId)
    if (email) {
      onVerificationEmailSent(email)
    }
    layoutContext.setLoadingState(false)
  }

  return (
    <p className="underline cursor-pointer hover:underline" onClick={onResendVerificationEmail}>
      {t('login:LoginPage.resendVerificationEmail')}
    </p>
  )
}
