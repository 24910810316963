
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import View from '../../components/layout/View';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { BackButton } from '../../components/core/Button';
import { buildSessionData, withPageSession } from '../../lib/session';
import { useAccountClient } from '../../lib/api-client';
import Fingerprint2 from 'fingerprintjs2';
import { setNoCacheHeader } from '../../util/caching';
import { CmsPageIdentifier } from '../../lib/content';
import { PageFilterType } from '../../filter/types';
import Logo from '../../components/layout/Logo';
import { LoginForm } from '../../components/account/forms/LoginForm';
import { TfaModal } from '../../components/account/modals/TfaModal';
import { getCmsContentDataField } from '../../util/content';
import { Constants } from '../../util/constants';
import { FaceRecognitionModal } from '../../components/account/modals/FaceRecognitionModal';
import { useExatoFaceRecognition } from '../../hooks/useExatoFaceRecognition';
const Login = ({ cmsPage, registrationDisabled }) => {
    const { t } = useTranslation('login');
    const [tfaModalActive, setTfaModalActive] = useState(false);
    const [tfaToken, setTfaToken] = useState(null);
    const [fingerprint, setFingerprint] = useState(null);
    const exatoFaceRecognition = useExatoFaceRecognition();
    const headline = getCmsContentDataField(cmsPage, 'head_line')?.value?.str;
    const body = getCmsContentDataField(cmsPage, 'body')?.value?.str;
    useEffect(() => {
        loadFingerprint();
    }, []);
    const loadFingerprint = () => {
        Fingerprint2.get(Constants.FingerprintSettings, (components) => {
            const values = components.map((component) => component.value);
            const fp = Fingerprint2.x64hash128(values.join(''), 31);
            setFingerprint(fp);
        });
    };
    const onTfaRequired = (_tfaToken: string) => {
        setTfaToken(_tfaToken);
        setTfaModalActive(true);
    };
    const onTfaModalClosed = () => {
        setTfaModalActive(false);
    };
    return (<div className="login px-4 py-8">
      <div className="login-header flex items-center space-x-2 text-[28px] font-semibold mb-8">
        <BackButton url="/sports"/>
        <h1 className="login-header-title">{t('LoginPage.title')}</h1>
      </div>

      <div className="login-card flex flex-col md:rounded-lg md:shadow-2xl md:p-8 md:bg-card md:border-[1px] border-neutral">
        <Logo className="login-card-logo mb-8"/>

        {(headline || body) && (<div className="login-card-text flex flex-col space-y-2 mb-8">
            {headline && <span className="login-card-text-headline text-xl font-bold">{headline}</span>}
            {body && <div className="login-card-text-body text-sm" dangerouslySetInnerHTML={{ __html: body }}/>}
          </div>)}

        <LoginForm onTfaRequired={onTfaRequired} exatoFaceRecognitionFormProps={exatoFaceRecognition.formProps} fingerprint={fingerprint}/>

        {!registrationDisabled && (<div className="login-card-register flex justify-center items-center text-xs mt-1 p-2">
            <span className="login-card-register-subtitle">{t('LoginPage.subtitle')}&nbsp;</span>
            <Link href="/account/register" className="login-card-register-link text-primary font-semibold cursor-pointer hover:underline">
              {t('LoginPage.register')}
            </Link>
          </div>)}
      </div>

      {tfaModalActive && <TfaModal onModalClose={onTfaModalClosed} tfaToken={tfaToken} fingerprint={fingerprint}/>}

      {exatoFaceRecognition.active && (<FaceRecognitionModal onModalClose={exatoFaceRecognition.onFaceRecognitionModalClosed} faceRecognitionUrl={exatoFaceRecognition.url}/>)}
    </div>);
};
Login.getLayout = function getLayout(_t, pageProps, page) {
    return (<View pageId="account-login-page" addHeader={false} addFooter={false} addBottomNav={false} maxMainContentWidth={'500px'}>
      {page}
    </View>);
};
const getServerSideProps = withPageSession(async ({ req, res, session, locale }, _pageFilterResult, pageData) => {
    setNoCacheHeader(res);
    const authenticated = session.get('authenticated');
    if (authenticated) {
        return {
            redirect: {
                destination: `/${locale}/sports`,
                permanent: false,
            },
        };
    }
    const accountClient = useAccountClient();
    const sessionId = session.get('sessionId');
    const token = session.get('token');
    const sessionData = buildSessionData(req, sessionId, token);
    const branchSecuritySettings = await accountClient.getBranchSecuritySettings(sessionData);
    return {
        props: {
            ...pageData,
            registrationDisabled: pageData.frontendSettings?.disableRegistrations || branchSecuritySettings.customerRegistrationSuspended,
        },
    };
}, CmsPageIdentifier.Login, [PageFilterType.WebsiteRequiresAuthentication, PageFilterType.PageRequiresAuthentication]);
export default Login;

    async function __Next_Translate__getServerSideProps__195fb599ce0__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/account/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195fb599ce0__ as getServerSideProps }
  