import { useAnalyticSettings } from '../lib/content'
import { isNullOrEmpty } from '../util/string'
import { usePageCookies } from './usePageCookies'

const useSportRadarTagManager = () => {
  const analyticSettings = useAnalyticSettings()
  const [, performanceCookiesAccepted] = usePageCookies()

  const enabled =
    (analyticSettings?.sportRadar?.enabled ?? false) &&
    !isNullOrEmpty(analyticSettings?.sportRadar?.clientId) &&
    performanceCookiesAccepted

  const srtmPushRegistrationStarted = () => {
    if (typeof srtmCommands === 'undefined' || !enabled) return

    try {
      srtmCommands({
        event: 'track.user.registration',
        payload: {
          action: 'start',
        },
      })
    } catch (error) {
      console.log('SRTM: could not track registration started event')
    }
  }
  const srtmPushRegistrationFinished = (userId: number, promotionCode: string) => {
    if (typeof srtmCommands === 'undefined' || !enabled) return

    try {
      srtmCommands({
        event: 'track.user.registration',
        payload: {
          action: 'complete',
          userId,
          promotionCode,
        },
      })
    } catch (error) {
      console.log('SRTM: could not track registration event')
    }
  }
  const srtmPushLogin = (userId: number) => {
    if (typeof srtmCommands === 'undefined' || !enabled) return

    try {
      srtmCommands({
        event: 'track.user.login',
        payload: {
          action: 'complete',
          userId,
        },
      })
    } catch (error) {
      console.log('SRTM: could not track login event')
    }
  }

  const srtmPushDepositEvent = (
    userId: number,
    transactionId: any,
    amount: number,
    currency: any,
    depositCount: number
  ) => {
    if (typeof srtmCommands === 'undefined' || !enabled) return

    const srtmEvent: any = {
      event: 'track.betting.deposit',
      payload: {
        action: 'created',
        userId,
        transactionId,
        amount,
        currency,
      },
    }

    if (depositCount === 1) {
      srtmEvent.payload.label = 'first_deposit'
    }

    try {
      srtmCommands(srtmEvent)
    } catch (error) {
      console.log('SRTM: could not track deposit event')
    }
  }

  return {
    srtmPushRegistrationStarted,
    srtmPushRegistrationFinished,
    srtmPushLogin,
    srtmPushDepositEvent,
  }
}

declare function srtmCommands(event: any)

export default useSportRadarTagManager
