import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  redirectAfterSuccessfulLogin,
  signInTfaUser,
  useUser,
  useUserAccounts,
  useUserBetSettings,
} from '../../../lib/user'
import { ContainedButton } from '../../core/Button'
import Modal from '../../core/Modal'
import PinField from '../../core/PinField'
import { EnumTranslator } from '../../../util/enum-translator'
import { TokenValidationError } from '@arland-bmnext/api-data'

export const TfaModal = ({ onModalClose, tfaToken, fingerprint }) => {
  const { mutateUser } = useUser()
  const { mutateUserAccounts } = useUserAccounts()
  const { mutateUserBetSettings } = useUserBetSettings()
  const { t } = useTranslation('login')
  const [tfaPinCode, setTfaPinCode] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  console.log(errorMessage)

  const {
    locale,
    query: { redirectTo },
  } = useRouter()

  useEffect(() => {
    setErrorMessage(null)
  }, [tfaPinCode])

  const onPinChange = (value) => {
    setTfaPinCode(value)
  }

  const onClose = () => {
    onModalClose()
  }

  const onSubmit = async () => {
    const res = await signInTfaUser(tfaToken, tfaPinCode, fingerprint)

    switch (res?.validationError) {
      case TokenValidationError.None:
        await Promise.all([mutateUser(), mutateUserAccounts(), mutateUserBetSettings()])
        await redirectAfterSuccessfulLogin(res.session?.user, locale, redirectTo as string)
        break
      default:
        const error = EnumTranslator(t, TokenValidationError, res?.validationError ?? TokenValidationError.Other)
        setErrorMessage(error)
    }
  }

  return (
    <>
      <Modal title={t('TfaModal.title')} show={true} onClose={onClose} closable>
        <div className="tfa-modal flex flex-col items-center mb-4 last:mb-0">
          <p className="tfa-modal-description mb-6 opacity-70">{t('TfaModal.description')}</p>

          <PinField onPinChange={onPinChange} />

          <div className="tfa-modal-actions mt-8 w-full flex flex-col space-y-2">
            <ContainedButton
              className="tfa-modal-actions-submit"
              backgroundColor={errorMessage == null ? 'bg-primary' : 'bg-red-500'}
              textColor={errorMessage == null ? 'text-primaryContrast' : 'text-white'}
              borderColor={errorMessage == null ? 'border-primary' : 'border-red-500'}
              onClick={errorMessage == null ? onSubmit : null}
              disabled={tfaPinCode.replace(' ', '').length < 6}
              rounded
              fullWidth
            >
              {errorMessage == null ? t('common:Common.submit') : errorMessage}
            </ContainedButton>
          </div>
        </div>
      </Modal>
    </>
  )
}
