import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isNullOrEmpty } from '../util/string'
import { useLayoutContext } from '../context/layout.context'

type ExatoFaceRecognitionProps = {
  enabled?: boolean
  url?: string
}

type ExatoFaceRecognition = {
  active: boolean
  url: string
  onFaceRecognitionModalClosed: (identityValidationId?: string, faceRecognitionStatusMessage?: string) => void
  formProps: ExatoFaceRecognitionFormProps
}

export type ExatoFaceRecognitionFormProps = {
  enabled: boolean
  onFaceRecognitionRequired: (url?: string) => void
  identityValidationId?: string
  statusMessage?: string
}

export const useExatoFaceRecognition = (props?: ExatoFaceRecognitionProps): ExatoFaceRecognition => {
  const [enabled, setEnabled] = useState(props?.enabled ?? true)
  const [active, setActive] = useState(false)
  const [url, setUrl] = useState(props?.url ?? null)
  const [identityValidationId, setIdentityValidationId] = useState<string>(null)
  const [statusMessage, setStatusMessage] = useState(null)

  const onFaceRecognitionRequired = (_url?: string) => {
    setActive(true)
    if (_url != null) setUrl(_url)
  }

  const onFaceRecognitionModalClosed = (identityValidationId?: string, faceRecognitionStatusMessage?: string) => {
    setActive(false)
    setIdentityValidationId(identityValidationId)
    setStatusMessage(faceRecognitionStatusMessage)
  }

  const formProps: ExatoFaceRecognitionFormProps = {
    enabled,
    onFaceRecognitionRequired,
    identityValidationId,
    statusMessage,
  }

  return {
    active,
    url,
    onFaceRecognitionModalClosed,
    formProps,
  }
}

export const useExatoFaceRecognitionResultHandler = (
  setStatusMessage: Dispatch<SetStateAction<string>>,
  onSubmit: (...args: any) => Promise<any>,
  identityValidationId?: string,
  statusMessage?: string,
) => {
  const layoutContext = useLayoutContext()

  useEffect(() => {
    let timeout = null

    if (!isNullOrEmpty(statusMessage) && setStatusMessage != null) {
      setStatusMessage(statusMessage)
    }

    if (!isNullOrEmpty(identityValidationId)) {
      layoutContext.setLoadingState(true)

      timeout = setTimeout(async () => {
        try {
          await onSubmit()
        } finally {
          layoutContext.setLoadingState(false)
        }
      }, 2000)
    }

    return () => {
      if (layoutContext.loadingState?.loading) {
        layoutContext.setLoadingState(false)
      }

      if (timeout != null) {
        clearTimeout(timeout)
      }
    }
  }, [identityValidationId, statusMessage])
}
