import Modal from '../../core/Modal'
import { useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

export type ExatoStatusPostMessage = {
  Cpf: string
  IdentityValidationId: string
  Message: string
  MessageCode: string
  PublicKeyId: string
  RedirectUrl: string
  Status: string
  Success: boolean
}

export const FaceRecognitionModal = ({ onModalClose, faceRecognitionUrl }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('message', onDocCheckMessage)

    return () => {
      window.removeEventListener('message', onDocCheckMessage)
    }
  }, [])

  const onDocCheckMessage = (event) => {
    const exDocCheckHost = ['https://doccheck.exato.digital']

    if (exDocCheckHost.includes(event.origin)) {
      const statusPostMessage: ExatoStatusPostMessage = event.data.StatusPostMessage

      if (statusPostMessage) {
        console.log('StatusPostMessage', statusPostMessage)

        let statusMessage = t('common:Common.ExatoFaceRecognitionStatus.' + statusPostMessage.Status)
        const identityValidationId: string = statusPostMessage.IdentityValidationId

        switch (statusPostMessage.Status) {
          case 'APROVACAO_AUTOMATICA':
            onModalClose(identityValidationId, null)
            break
          default:
            onModalClose(null, statusMessage)
            break
        }
      }
    } else {
      return
    }
  }

  return (
    <>
      <Modal show={true} wrapperClassName={'w-screen h-screen !p-2'} contentClassName={'h-full w-full'}>
        <iframe
          className={'h-full w-full rounded'}
          src={faceRecognitionUrl}
          allow="fullscreen https://doccheck.exato.digital; camera https://doccheck.exato.digital;"
          allowFullScreen
        />
      </Modal>
    </>
  )
}
