import React, { useState } from 'react'

type PinFormFieldProps = {
  index: number
  onChange: (index: number, value: string) => void
}

const PinFormField = ({ index, onChange }: PinFormFieldProps) => {
  const [value, setValue] = useState('')

  const onValueChange = (ev) => {
    const element = ev?.target
    if (element.validity.valid) {
      const value = element?.value
      setValue(value)
      onChange(index, value)
    }
  }

  const onClick = (ev) => {
    ev?.target?.select()
  }

  return (
    <input
      id={'pin#' + index}
      className={`pin-form-field flex items-center text-center p-2 w-[36px] border-[1px] border-formBorder bg-form text-formContrast font-semibold rounded-lg focus:border-primary focus:outline-none caret-transparent hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder ${index === 2 ? '!mr-[6px]' : ''}`}
      value={value}
      onChange={onValueChange}
      onClick={onClick}
      type="number"
      inputMode="numeric"
      min={0}
      max={9}
    />
  )
}

export type PinFormProps = {
  onPinChange: (currentValue: string) => void
}

const PinForm = ({ onPinChange }: PinFormProps) => {
  const [pin, setPin] = useState([])

  const onPinFormFieldChange = (index: number, value: string) => {
    const _pin = [...pin]
    _pin[index] = value
    setPin(_pin)

    if (value != '') {
      if (index < 5) {
        focusPinFormField(index + 1)
      } else {
        ;(document.activeElement as any)?.blur()
      }
    }

    onPinChange(_pin.join(''))
  }

  const focusPinFormField = (index: number) => {
    const element: HTMLInputElement = document.getElementById('pin#' + index) as HTMLInputElement
    element?.focus()
    element?.select()
  }

  return (
    <div className="pin-form flex justify-center w-full space-x-[6px]">
      {[...Array(6)].map((e, i) => (
        <React.Fragment key={i}>
          <PinFormField index={i} onChange={onPinFormFieldChange} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default PinForm
